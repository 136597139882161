// @import url(https://diegoddox.github.io/react-redux-toastr/4.4/react-redux-toastr.min.css);
html body {
  font-family: 'Roboto';
}

h1,
h2,
h3 {
  font-family: 'Roboto';
}
.react-select {
  div[class*='MenuList']::-webkit-scrollbar {
    width: 2px;
    border-radius: 6px;
  }
  div[class*='MenuList']::-webkit-scrollbar-thumb {
    background-color: #ffcc4d;
    outline: 1px solid #ffcc4d;
  }
}

.redux-toastr {
  width: 100vw;
  .toastr {
    min-height: 47px;
    max-width: 350px;
    .close-toastr {
      top: 6px;
      right: -10px;
      opacity: 0.9;
    }
  }
  .rrt-progress-container {
    display: none;
  }
}
