/* bring in normalize.css styles */

/* TODO: Dynamic import theme file and uncomment this */

/* @import-normalize; */

/* Global CSS */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400');

$grid-breakpoints: (
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1540,
);

$font-family-base: 'Roboto', sans-serif;

// $container-max-widths: (
//   sm: 540,
//   md: 720,
//   lg: 960,
//   xl: 1140,
//   xxl: 1240,
//   xxxl: 1510,
// );

$font-size-base: 1rem;

// @import 'bootstrap';

html body {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: $font-size-base;
  color: #5a6168;
  -webkit-font-smoothing: antialiased;
}

html body a {
  color: #4378ff;
  margin: 0;
}

label {
  color: #2a2a3e;
}

h1,
h2,
h3 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  line-height: 37px;
  color: #5a6168;
}

body h5 {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

body h4,
body .h4 {
  font-size: 20px;
}
// html body h1 {
//   font-size: 32px;
// }

html body h3 {
  font-size: 20px;
}

html ul {
  padding-left: 0;
  li {
    //list-style: none;
    margin: 0;
  }
}

@mixin arrow($color, $rotate) {
  border-bottom: 2px solid $color;
  border-right: 2px solid $color;
  transform: rotate($rotate);
  width: 8px;
  height: 8px;
}

.label-arrow-down {
  @include arrow(#6956ba, 45deg);
}
.label-arrow-up {
  @include arrow(#6956ba, -135deg);
}

.header-arrow-down {
  @include arrow(#fff, 45deg);
}
.header-arrow-up {
  @include arrow(#fff, -135deg);
}

.table-arrow-left {
  @include arrow(#000, 135deg);
  margin: 6px 2px;
}

.table-arrow-down {
  @include arrow(#6956ba, 45deg);
  margin: 6px 2px;
}

.table-arrow-up {
  @include arrow(#6956ba, -135deg);
  margin: 6px 2px;
}

.table-arrow-right {
  @include arrow(#000, -45deg);
  margin: 6px 2px;
}

.text-small {
  font-size: $font-size-base * 0.9;
}

.header-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
}

.back-arrow {
  @include arrow(#4378ff, 135deg);
}

.pagination {
  .page-item {
    .page-link {
      color: #000;
      border: 1px solid #eceeef;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 15px;
      padding-bottom: 15px;
      &:focus {
        box-shadow: none;
      }
    }
    &.active .page-link {
      z-index: 3;
      color: #fff;
      background: #2a2a3e;
      border-color: #2a2a3e;
    }
  }
}

.table-note {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 0;
  color: #8a8f93;
}

.react-bootstrap-table {
  .table {
    thead th {
      color: #333333;
      font-size: $font-size-base * 0.85;
      font-weight: 500;
      padding-top: 1rem;
      padding-bottom: 1.2rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      border-bottom: 0;
      background-color: #dde0ff;
      border-top: none;
    }
    td {
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      border-top: 0;
      font-size: $font-size-base * 0.85;
    }
    tbody tr {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    }
    td.react-bs-table-no-data {
      text-align: center;
    }
    th:first-child {
      border-top-left-radius: 8px;
    }
    th:last-child {
      border-top-right-radius: 8px;
    }
    tr:last-child td:first-child {
      border-bottom-left-radius: 8px;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 8px;
    }
  }
}

.pagination {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  li.page-item[title='First page'] {
    position: relative;
    width: 54px;
    &::after {
      content: '';
      display: inline;
      clear: both;
      position: absolute;
      color: red;
      top: 23px;
      left: 23px;
      z-index: 2;
      @include arrow(#000, -45deg);
    }
    a {
      text-indent: -9999px;
    }
  }
}

button,
button.btn {
  font-family: 'Roboto';
  &:focus,
  &:hover,
  &:active {
    box-shadow: none;
  }
}

.tvs-breadcrumb {
  li:not(:last-child) {
    &:after {
      clear: both;
      display: inline-block;
      content: '';
      margin-left: 15px;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid #d9d9dd;
      width: 15px;
    }
  }
  li,
  li {
    list-style: none;
  }
  li a {
    color: #666;
    font-weight: 400;
  }
  li a:hover {
    color: #333;
    font-weight: 400;
  }
  li:last-child {
    a {
      font-weight: 500;
      pointer-events: none;
      cursor: default;
    }
  }
}
.acct_number-text-color {
  color: rgba(42, 42, 62, 0.75);
}

.label-color {
  color: rgba(42, 42, 62, 0.5);
  font-size: $font-size-base * 0.9;
}

.record-label {
  font-family: 'Roboto';
  font-style: bold;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
}

.record-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}

.phone-color {
  color: #2a2a3e;
}

body .card {
  border: none;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  .card-header {
    font-size: 16px;
    font-weight: 500;
    color: #263238;
  }
}

.tooltip-inner {
  background-color: #fff2d0 !important;
  color: #2a2a3e !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
}

.tooltip .arrow:before {
  border-top-color: #fff2d0 !important;
}
.tooltip.show {
  opacity: 1 !important;
}
.tooltip .tooltip-inner {
  max-width: 400px;
  text-align: left;
}

.lazyload-wrapper {
  justify-content: center;
  text-align: center;
  display: flex;
}

.text-normal {
  font-size: 16px;
}

.dropdown {
  .dropdown-menu {
    border-radius: 0%;
    min-width: 270px;
    max-height: 250px;
    overflow-y: auto;
    border: none;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 4px 6px -1px rgb(0 0 0 / 0.1);
    // border: 1px solid #5a6168;
    // &.show {
    //   border: 1px solid #5a6168;
    // }
    padding: 0;
  }
  .dropdown-toggle:not(.bg):after {
    display: inline-block;
    height: 9px;
    width: 9px;
    border-top: 2px solid #6956ba;
    border-right: 2px solid #6956ba;
    border-left: none;
    transform: rotate(135deg);
    margin-left: 10px;
  }
  .dropdown-toggle.bg:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown-item {
    border-bottom: 1px solid #e5e3e3;
    font-size: 16px;
    color: #2a2a3e;
    padding: 10px 20px;
    &:hover,
    &:focus,
    &:active {
      background-color: #f2f3ff;
      font-weight: 500;
      color: #2a2a3e;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.card .card-header {
  background: #fff;
  align-items: center;
}

.green {
  fill: #39c86a;
  stroke: transparent;
  opacity: 1;
}
.orange {
  fill: #ff8a65;
  stroke: transparent;
  opacity: 1;
}
.yellow {
  fill: #f5c324;
  stroke: transparent;
  opacity: 1;
}

input.form-control {
  height: 50px;
  border-radius: 2px;
  &:disabled {
    background-color: hsl(0, 0%, 95%);
    color: #a4a4a4;
  }
}

button.btn {
  border-radius: 2px;
  &.disabled {
    color: #808080;
  }
  &.btn-primary {
    background-color: #2a2a3e;
    border: 1px solid #2a2a3e;
    &.disabled {
      color: #fff;
    }
  }
  &.btn-cancel {
    background-color: #fff;
    border: 1px solid #e44040;
    color: #e44040;
  }
  &.btn-link {
    border: 1px solid #4378ff;
    color: #4378ff;
  }
}

form button.btn {
  padding: 12px 30px;
  font-size: 16px;
}

.custom-checkbox label.custom-control-label {
  margin-left: 0.25rem;
  &:before,
  &:after {
    position: absolute;
    top: 0rem;
    left: -1.75rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 2px;
  }
}

textarea.form-control {
  border-radius: 2px;
  min-height: 100px;
}

.compare-span {
  font-size: 14px;
  color: #fff;
  padding: 0px 10px;
  border-radius: 20px;
  align-self: center;
  margin-right: 24px;
  &.up,
  &.undefined,
  &.nil {
    background: #39c86a;
  }
  &.down {
    background: #ff8a65;
  }
}

.sm-text {
  color: #7d8488;
  font-size: 12px;
  line-height: 20px;
}

.text-xs {
  font-size: 12px;
  font-weight: 300;
}
.text-s {
  font-size: 15px !important;
}

.bold {
  font-weight: 500 !important;
}

.text-normal {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.ascending-sort {
  position: relative;
  &:after {
    clear: both;
    content: '';
    display: inline;
    width: 16px;
    height: 16px;
    background: url('../public/images/ascending-sort.png') no-repeat;
    position: absolute;
    right: 0;
    top: 4px;
  }
}

.table {
  th {
    vertical-align: middle !important;
  }
  .sortable {
    cursor: pointer;
  }
  .react-bootstrap-table-sort-order {
    margin-left: 5px;
  }
  // .sortable .caret {
  //   display: inline-block;
  //   margin-left: 0.255em;
  //   vertical-align: 0.255em;
  //   content: '';
  //   border-top: 0.3em solid;
  //   border-right: 0.3em solid transparent;
  //   border-bottom: 0;
  //   border-left: 0.3em solid transparent;
  // }
  // .sortable .caret {
  //   display: inline-block;
  //   margin-left: 0.255em;
  //   vertical-align: 0.255em;
  //   content: '';
  //   border-bottom: 0.3em solid;
  //   border-right: 0.3em solid transparent;
  //   border-top: 0;
  //   border-left: 0.3em solid transparent;
  // }
}
.redux-toastr {
  z-index: 10000;
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  .rrt-success {
    padding: 8px 10px;
    background-color: green;
    border-radius: 3px;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 5%);
    .rrt-text,
    .close-toastr {
      color: #fff;
    }
    .toastr-icon {
      fill: #fff !important;
    }
  }
  .rrt-left-container {
    margin-right: 30px;
  }
  .rrt-right-container {
    margin-left: 30px;
  }
  .rrt-error {
    background-color: #e44040 !important;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 5%);
    .rrt-text {
      color: #fff;
    }
  }
  .rrt-warning {
    background-color: #ffcc4d;
    padding: 10px;
    border-radius: 2px;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 5%);
  }
  .rrt-left-container {
    align-self: center;
  }
  .rrt-holder {
    position: relative;
    svg {
      position: absolute;
      top: -16px;
    }
    // &::before {
    //   content: '';
    //   clear: both;
    //   display: block;
    //   position: absolute;
    //   background-color: #fff;
    //   top: -8px;
    //   left: 6px;
    //   width: 11px;
    //   height: 15px;
    //   z-index: 0;
    // }
  }
  .close-toastr {
    color: #fff;
  }
  .rrt-warning:focus,
  .rrt-error:focus,
  .rrt-success {
    cursor: initial;
    outline: none;
  }
  .rrt-warning > div,
  .rrt-error > div,
  .rrt-success > div {
    display: flex;
  }
  .rrt-middle-container {
    min-width: 300px;
    padding: 0 5px;
    align-self: center;
  }
  .rrt-title {
    font-weight: 500;
    color: #fff;
  }
  .toastr-icon {
    fill: #5a6168 !important;
  }
}
.nav-item {
  align-self: center;
  padding-bottom: 5px;
  &.active a {
    padding-bottom: 5px;
    font-weight: 500;
    border-bottom: 1px solid #fff;
  }
  a:hover {
    padding-bottom: 5px;
    text-decoration: none;
    color: #fff;
    border-bottom: 1px solid #fff;
  }
}

._loading_overlay_overlay {
  background: #d9d9dd !important;
  opacity: 0.5 !important;
}

.circle-close {
  background: url('../public/images/close-circle.png') no-repeat;
}

.status.up,
.status.down {
  background: url('../public/images/up-arrow.png') no-repeat;
  width: 30px;
  height: 18px;
  align-self: center;
  margin-left: 5px;
}

.status.down {
  transform: rotate(180deg);
}

input[type='text']:focus-visible {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
  border: 1px solid #80bdff;
  outline: none;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.rrt-right-container,
.rrt-left-container {
  align-self: center;
}

.react-select input[type='text'] {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.cs-detail {
  .nav-tabs .nav-item {
    padding-bottom: 0;
  }
  .nav-tabs .nav-item .nav-link {
    font-size: 20px;
    color: #2a2a3e;
    background-color: transparent;
    border: 1px solid transparent;
    padding-bottom: 5px;
    cursor: pointer;
  }
  .nav-tabs .nav-item .nav-link.active {
    color: #4378ff;
    border-bottom: 1px solid #4378ff;
  }
}

.cs-detail .react-bootstrap-table .table thead th {
  border-top: 0;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e5e3e3 !important;
  opacity: 1;
}

._2xcMq {
  // nepali calendar datepicker style
  width: 200%;
}

.daily-collection-stats-card {
  background: linear-gradient(180deg, #dde0ff 0%, #f2f3ff 100%);
  box-shadow: 0px 0px 5px rgba(51, 51, 51, 0.2);
  border-radius: 8px !important;
}

.daily-collection-card-label {
  color: #666666;
  font-size: 12px;
  line-height: 16px;
}

.multi-select-hover-color {
  &:hover {
    background-color: #f2f3ff;
  }
}

.div-checked {
  background-color: #f2f3ff;
}

.menus {
  display: flex;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
}

.arrow::after {
  content: '';
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.cluster-dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.cluster-dropdown.show {
  display: block;
}

.cluster-dropdown .cluster-dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

@media (min-width: 576px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 1440px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1440px;
  }
}

.itinerary-modal {
  background-color: #333333;
  opacity: 0.95;
}
